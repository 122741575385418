import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import 'assets/stylesheets/application.scss'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Layout = ({ children, pageName }) => {
  let className = ''

  if (pageName) {
    className = `${className} page-${pageName}`
  }

  return (
    <Fragment>
      <Helmet bodyAttributes={{ class: className }} />
      <div className="wrapper">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
