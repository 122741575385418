import React, { Component } from 'react'
import { Link } from 'gatsby'
import Container from '../Container/Container'
import Image from '../Image/Image'

import withSizes from 'react-sizes'
import { slide as MobileMenu } from 'react-burger-menu'

import BurgerIcon from '../../assets/images/icons/burger.svg'
import CrossIcon from '../../assets/images/icons/cross.svg'
import ElipseCircle from '../../assets/images/mobile-menu/elipse-circle.svg'
import Elipse from '../../assets/images/mobile-menu/elipse.svg'
import Polygon from '../../assets/images/mobile-menu/polygon.svg'
import Rectangle from '../../assets/images/mobile-menu/rectangle.svg'
class Header extends Component {
  state = {
    width: 1100
  }

  componentDidUpdate(prevProps, prevState) {
    // If the hamburger menu is open, disable scrolling on the site
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        // overflow:hidden disables the scrolling on a desktop browser
        // position: fixed is additionally needed for mobile devices
        document.body.setAttribute(
          'style',
          'overflow: hidden; position: fixed;'
        )
      } else {
        document.body.setAttribute(
          'style',
          'overflow-x: hidden; position: static;'
        )
      }
    }
  }

  handleStateChange(state) {
    this.setState({ isOpen: state.isOpen })
  }

  closeMenu = () => {
    this.setState({
      isOpen: false
    })
  }

  render() {
    const { isOpen } = this.state
    const { isMobile } = this.props

    const getMenu = () => {
      if (isMobile) {
        return (
          <MobileMenu
            isOpen={isOpen}
            onStateChange={state => this.handleStateChange(state)}
            customBurgerIcon={<img src={BurgerIcon} alt="Open menu" />}
            burgerButtonClassName="menu-mobile__open"
            customCrossIcon={<img src={CrossIcon} alt="Close menu" />}
            crossButtonClassName="menu-mobile__close"
            className="menu-mobile"
            right
            disableAutoFocus
          >
            <ul className="menu-mobile__list">
              <li>
                <a href="/" onClick={this.closeMenu}>
                  Why Ally?
                </a>
              </li>
              <li>
                <p className="menu-mobile__paragraph">Ally for ></p>
                <ul className="menu-mobile__list menu-mobile__list--nested">
                  <li>
                    <Link to="/manager" onClick={this.closeMenu}>
                      Managers
                    </Link>
                  </li>
                  <li>
                    <Link to="/engineer" onClick={this.closeMenu}>
                      Engineers
                    </Link>
                  </li>
                  <li>
                    <Link to="/product-manager" onClick={this.closeMenu}>
                      Product leaders
                    </Link>
                  </li>
                  <li>
                    <Link to="/distributed-teams" onClick={this.closeMenu}>
                      Distributed Teams
                    </Link>
                  </li>
                  <li>
                    <Link to="/colocated-teams" onClick={this.closeMenu}>
                      Co-located Teams
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/pricing" onClick={this.closeMenu}>
                  Pricing
                </Link>
              </li>
              <p className="menu-mobile__paragraph">Resources ></p>
              <ul className="menu-mobile__list menu-mobile__list--nested">
                <li>
                  <Link to="/blog" onClick={this.closeMenu}>
                    Blog
                  </Link>
                </li>
                <li>
                  <a href="https://app.getally.com" onClick={this.closeMenu}>
                    Help
                  </a>
                </li>
              </ul>
            </ul>

            <div className="menu-mobile__bottom">
              <ul className="menu-mobile__list menu-mobile__list--horizontal">
                <li>
                  <a href="https://app.getally.com">Login</a>
                </li>
                <li>
                  <a href="https://app.getally.com">Sign up</a>
                </li>
              </ul>

              <a
                href="https://app.getally.com"
                className="menu-mobile__btn btn btn--blue btn--big"
              >
                Get Started
              </a>

              <div className="menu-mobile__line menu-mobile__line--bottom" />
            </div>

            <div className="menu-mobile__line" />
            <div className="menu-mobile__shapes">
              <img
                src={ElipseCircle}
                className="shape-elipse-circle"
                alt="Elips"
              />
              <img src={Elipse} className="shape-elips" alt="Elips" />
              <img src={Polygon} className="shape-polygon-1" alt="Polygon" />
              <img
                src={Rectangle}
                className="shape-rectangle"
                alt="Rectangle"
              />
              <img src={Polygon} className="shape-polygon-2" alt="Polygon" />
            </div>
          </MobileMenu>
        )
      } else {
        return (
          <nav className="menu-desktop">
            <Link to="/" className="menu-desktop__logo">
              <Image
                filename="logo.png"
                className="menu-desktop__logo"
                alt="Ally"
              />
            </Link>
            <ul className="menu-desktop__list">
              <li>
                <a href="/">Why Ally?</a>
              </li>
              <li className="menu-desktop__dropdown-link">
                Ally for
                <div className="menu-desktop__dropdown-wrapper">
                  <ul className="menu-desktop__dropdown-list">
                    <li>
                      <Link to="/manager">Managers</Link>
                    </li>
                    <li>
                      <Link to="/engineer">Engineers</Link>
                    </li>
                    <li>
                      <Link to="/product-manager">Product Leaders</Link>
                    </li>
                    <li>
                      <Link to="/distributed-teams">Distributed teams</Link>
                    </li>
                    <li>
                      <Link to="/colocated-teams">Co-located teams</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to="/pricing" activeClassName="active">
                  Pricing
                </Link>
              </li>
              <li className="menu-desktop__dropdown-link">
                Resources
                <div className="menu-desktop__dropdown-wrapper">
                  <ul className="menu-desktop__dropdown-list">
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="https://help.getally.com">Help</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="https://app.getally.com">Login</a>
              </li>
              <li>
                <a href="https://app.getally.com" className="btn btn--blue">
                  Get Started
                </a>
              </li>
            </ul>
          </nav>
        )
      }
    }

    return (
      <header className={isMobile ? 'header header--mobile' : 'header'}>
        <Container>{getMenu()}</Container>

        {isMobile && (
          <div className="header__mobile-brand">
            <Link to="/" className="menu-desktop__logo">
              <Image
                filename="logo.png"
                className="menu-desktop__logo"
                alt="Ally"
              />
            </Link>
          </div>
        )}
      </header>
    )
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 800
})

export default withSizes(mapSizesToProps)(Header)
